import React, { Component } from 'react';
import * as constClass from '../constants/agent.js';
import theme from '../styles/theme.css';  // Important style for AutoSuggest component
import Autosuggest from 'react-autosuggest';

// Fetch airport suggestions from flight-api backend.
async function fetchSuggestions(inputValue) {
  console.log("Requesting airport data from the backend")

  const backend_airports_url = constClass.AUTOCOMPLETE_AIRPORT_BACKEND_URL;
  const backend_airports_search_url = backend_airports_url + `?q=${inputValue}`
  //console.log("backend_airports_search_url:")
  //console.log(backend_airports_search_url)
  const response = await fetch(backend_airports_search_url)
  const json = await response.json();
  console.log("Airport data returned by flight-api")
  console.log(json)

  return json;
}

/* --------------- */
/*    Component    */
/* --------------- */

function getSuggestionValue(suggestion) {
  let airportPresentationString = `${suggestion.name} (${suggestion.code})`
  return airportPresentationString;
}

function renderSuggestion(suggestion, isHighlighted=true) {
  return (
    <>
      <span>{suggestion.municipality} - {suggestion.name} ({suggestion.code})</span>
    </>
  );
}

function renderSuggestionSkeleton(suggestion) {
  return (
    <>
      <span>Loading ...</span>
    </>
  )
}

class AirportSuggestionsInput extends Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      isLoading: false
    };

    this.lastRequestId = null;
  }

  loadSuggestions(value) {
    // Cancel the previous request
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId);
    }

    // Set dummy suggestion to render the "Loading ..."
    this.setState({
      isLoading: true,
      suggestions: [
        {
          "code": "",
          "name": "",
          "iso_country": "",
          "municipality": "",
        }
      ]
    });

    // Fake request
    this.lastRequestId = setTimeout(async () => {
      this.setState({
        isLoading: false,
        suggestions: await fetchSuggestions(value)
      });
    }, 100);
  }

  handleOnFocus = (event) => {
    // Select all input text. Easier for the user to type in new destinations.
    event.target.select();
  };

  
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { suggestions, isLoading } = this.state;
    const { value, description } = this.props;
    const inputProps = {
      placeholder: "Search for an airport",
      value,
      onFocus: this.handleOnFocus,
      onChange: this.props.onChange,
      className: (this.props.iata_code_valid ? "form-control form-control-lg form-control" : "form-control form-control-lg form-control is-invalid") // Add bootstrap form-control
      //onChange: this.props.onChange,
    };

    return (
      <div>
        <div className="status">
          <strong>{description}</strong>
        </div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={isLoading ? renderSuggestionSkeleton : renderSuggestion}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    );
  }
}

export default AirportSuggestionsInput;
