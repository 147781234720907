import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';

const BlogPost = ({ entries }) => {
  // State
  const [markdown, setMarkdown] = useState('');
  const { slug } = useParams(); // extract the 'slug' parameter from the URL
  const entry = entries.find(entry => entry.slug === slug); // find the correct blog post object based on the 'slug'

  useEffect(() => {
    fetch(entry.content_path)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  });

  return (
    <div>
      <Container className="text-left">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/blog' }}>Blog</Breadcrumb.Item>
              <Breadcrumb.Item active>{entry.title}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <h2>{entry.title}</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <p><em>By {entry.author} on {entry.date}</em></p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlogPost;
