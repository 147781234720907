import {
    BACKEND_URL,
  } from '../constants/agent';
  

const requests = {
    del: async (url) => {
      let headers = {
        'Content-type': 'application/json'
      }
  
      const response = await fetch(`${BACKEND_URL}${url}`, {
                  method: 'DELETE',
                  headers: headers
              })
  
      return response
    },
    get: async (url) => {
      let headers = {
        'Content-type': 'application/json'
      }
  
      const response = await fetch(`${BACKEND_URL}${url}`, {
                  method: 'GET',
                  headers: headers
              })
  
      return response
    },
    put: async (url, body) => {
      let headers = {
        'Content-type': 'application/json'
      }
  
      const response = await fetch(`${BACKEND_URL}${url}`, {
          method: "PUT",
          body: JSON.stringify(body),
          headers: headers
      })
  
      return response
    },
    post: async (url, body) => {
      let headers = {
        'Content-type': 'application/json'
      }
  
      const response = await fetch(`${BACKEND_URL}${url}`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: headers
      })
  
      return response
    }
  };

const Stripe = {
  retrieveCheckoutSessionData: (checkout_session_id) =>
    requests.get(
      "/payment/success/" + checkout_session_id
    )
}

const FlightTicket = {
  orderTicketStripe: (
    first_name,
    last_name,
    email,
    airline,
    arrival_time,
    city_from,
    city_to,
    departure_time,
    flight_name,
    fly_duration,
    fly_from,
    fly_to,
    price,
    route,
    subscribe_to_newsletter,
    price_amount,
    // Pro Ticket Attributes
    additional_passengers,
    currency_symbol,
    optional_pnr_number,
    flight_class
  ) =>
    requests.post(
      '/payment/create-checkout-session/',
      { 
          "first_name": first_name,
          "last_name": last_name,
          "email": email,
          //"birthdate": "",
          "flight_name": flight_name,
          "departure_time": departure_time,
          "arrival_time": arrival_time,
          "fly_to": fly_to,
          "fly_from": fly_from,
          "city_to": city_to,
          "city_from": city_from,
          "price": price,
          "fly_duration": fly_duration, 
          "route": route,
          "airline": airline,
          "subscribe_to_newsletter": subscribe_to_newsletter,
          "price_amount": price_amount,
          "additional_passengers": additional_passengers,
          "currency_symbol": currency_symbol,
          "optional_pnr_number": optional_pnr_number,
          "flight_class": flight_class
      }
  ),
  orderTicketFree: (
    first_name,
    last_name,
    email,
    airline,
    arrival_time,
    city_from,
    city_to,
    departure_time,
    flight_name,
    fly_duration,
    fly_from,
    fly_to,
    price,
    route,
    subscribe_to_newsletter
) =>
  requests.post(
    '/ticket/hello.pdf',
    { 
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
          //"birthdate": "",
          "flight_name": flight_name,
        "departure_time": departure_time,
        "arrival_time": arrival_time,
        "fly_to": fly_to,
        "fly_from": fly_from,
        "city_to": city_to,
        "city_from": city_from,
        "price": price,
        "fly_duration": fly_duration, 
        "route": route,
        "airline": airline,
        "subscribe_to_newsletter": subscribe_to_newsletter
    }
  ),
  orderTicketCoinbase: (
    first_name,
    last_name,
    email,
    airline,
    arrival_time,
    city_from,
    city_to,
    departure_time,
    flight_name,
    fly_duration,
    fly_from,
    fly_to,
    price,
    route,
    subscribe_to_newsletter,
    price_amount,
    // Pro Ticket Attributes
    additional_passengers,
    currency_symbol,
    optional_pnr_number,
    flight_class
  ) =>
    requests.post(
      '/payment/create-coinbase-charge/',
      { 
          "first_name": first_name,
          "last_name": last_name,
          "email": email,
          //"birthdate": "",
          "flight_name": flight_name,
          "departure_time": departure_time,
          "arrival_time": arrival_time,
          "fly_to": fly_to,
          "fly_from": fly_from,
          "city_to": city_to,
          "city_from": city_from,
          "price": price,
          "fly_duration": fly_duration, 
          "route": route,
          "airline": airline,
          "subscribe_to_newsletter": subscribe_to_newsletter,
          "price_amount": price_amount,
          "additional_passengers": additional_passengers,
          "currency_symbol": currency_symbol,
          "optional_pnr_number": optional_pnr_number,
          "flight_class": flight_class
      }
  ),
  orderTicketPaypal: (
    first_name,
    last_name,
    email,
    airline,
    arrival_time,
    city_from,
    city_to,
    departure_time,
    flight_name,
    fly_duration,
    fly_from,
    fly_to,
    price,
    route,
    subscribe_to_newsletter,
    price_amount,
    // Pro Ticket Attributes
    additional_passengers,
    currency_symbol,
    optional_pnr_number,
    flight_class
  ) =>
    requests.post(
      '/payment/create-paypal-order/',
      { 
          "first_name": first_name,
          "last_name": last_name,
          "email": email,
          //"birthdate": "",
          "flight_name": flight_name,
          "departure_time": departure_time,
          "arrival_time": arrival_time,
          "fly_to": fly_to,
          "fly_from": fly_from,
          "city_to": city_to,
          "city_from": city_from,
          "price": price,
          "fly_duration": fly_duration, 
          "route": route,
          "airline": airline,
          "subscribe_to_newsletter": subscribe_to_newsletter,
          "price_amount": price_amount,
          "additional_passengers": additional_passengers,
          "currency_symbol": currency_symbol,
          "optional_pnr_number": optional_pnr_number,
          "flight_class": flight_class
      }
  ),
  orderTicketBlockonomics: (
    first_name,
    last_name,
    email,
    airline,
    arrival_time,
    city_from,
    city_to,
    departure_time,
    flight_name,
    fly_duration,
    fly_from,
    fly_to,
    price,
    route,
    subscribe_to_newsletter,
    price_amount,
    // Pro Ticket Attributes
    additional_passengers,
    currency_symbol,
    optional_pnr_number,
    flight_class
  ) =>
    requests.post(
      '/payment/create-crypto-charge/',
      { 
          "first_name": first_name,
          "last_name": last_name,
          "email": email,
          //"birthdate": "",
          "flight_name": flight_name,
          "departure_time": departure_time,
          "arrival_time": arrival_time,
          "fly_to": fly_to,
          "fly_from": fly_from,
          "city_to": city_to,
          "city_from": city_from,
          "price": price,
          "fly_duration": fly_duration, 
          "route": route,
          "airline": airline,
          "subscribe_to_newsletter": subscribe_to_newsletter,
          "price_amount": price_amount,
          "additional_passengers": additional_passengers,
          "currency_symbol": currency_symbol,
          "optional_pnr_number": optional_pnr_number,
          "flight_class": flight_class
      }
  ),
}

export {
  FlightTicket,
  Stripe
};