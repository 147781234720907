import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import '../styles/App.css';


const BasePage = (props) => { 
  return (
    <div className="d-flex flex-column min-vh-100">
        <header className="navbar-header bg-primary">
            <div className="row">
            <div className="col align-self-center">
                <h1 className="text-light">
                    <a className="text-light text-decoration-none" href="/">Onward Flight Ticket</a>
                </h1>
                <div className="row">
                <div className="col">
                    <h4 className="text-light">Dummy Ticket 2024</h4>
                </div>
                </div>
            </div>
            </div>
        </header>

        <Container className="flex-grow-1">
        <Row>
          <Col>
            {props.children}
          </Col>
        </Row>
      </Container>

        <footer className="bg-light py-3">
            <div className="footer-copyright text-center py-3">© 2024 Copyright:
                <div className="row justify-content-center">
                    <div className="col">
                        <a href="/">
                        onwardflightticket.com
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>For any requests contact: <a href="mailto:onwardflightticket@gmail.com">onwardflightticket@gmail.com</a></p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-2">
                        <a href="/terms">
                            Terms of service
                        </a>
                    </div>
                    <div className="col-md-2">
                        <a href="/privacy-policy">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  );
}

export default BasePage;
