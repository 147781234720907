import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function PrivacyPolicy() {
    return (
    <Container className="justify-content-center">
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Information Collection and Usage</h3>
            <p>
            We collect the following personal data from you when you use our website:
            </p>
            <ul>
                <li>Name: We use your name to create your personalized dummy ticket.</li>
                <li>Email address: We collect your email address when you make a purchase on our website so that we can send you a sample onward ticket.</li>
                <li>Usage data: We may collect information about how you use our website, such as the pages you visit, the links you click, and the amount of time you spend on each page. We use this information to improve our website and your user experience.</li>
            </ul>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Lawful Basis for Processing</h3>
            <p>
            We process your personal data on the basis of the following lawful grounds:
            </p>
            <ul>
                <li>Consent: We process your email address to send you the sample onward ticket you acquired.</li>
            </ul>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Information Use</h3>
            <p>
            We use the personal data we collect from you to:
            </p>
            <ul>
                <li>Send you the sample onward ticket you purchased.</li>
                <li>Improve our website and your user experience.</li>
                <li>Respond to your inquiries or support requests.</li>
            </ul>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Information Sharing</h3>
            <p>
            We share your personal data with the following third parties:
            </p>
            <ul>
                <li>
                We do not share your personal data with any other third parties, except as required by law.
                </li>
            </ul>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Information Security</h3>
            <p>
                We take reasonable measures to protect your personal data from unauthorized access, use, or disclosure. We use industry-standard security measures, such as encryption and firewalls, to protect your data.
            </p>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Your Rights</h3>
            <p>
            You have the following rights regarding your personal data:
            </p>
            <ul>
                <li>Right of access: You have the right to request access to the personal data we hold about you.</li>
                <li>Right of rectification: You have the right to request that we correct any inaccurate or incomplete personal data we hold about you.</li>
                <li>Right of erasure: You have the right to request that we delete your personal data.</li>
                <li>Right of objection: You have the right to object to the processing of your personal data on grounds relating to your particular situation.</li>
                <li>Right of restriction: You have the right to request that we restrict the processing of your personal data.</li>
                <li>Right of data portability: You have the right to receive a copy of your personal data in a structured, commonly used, and machine-readable format, and to transmit that data to another data controller.</li>
            </ul>
            <p>
            To exercise any of these rights, please contact us using the information provided below.
            </p>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Retention Period</h3>
            <p>
            We will retain your personal data for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.
            </p>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Changes to This Privacy Policy</h3>
            <p>
                We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. We encourage you to review this privacy policy periodically to stay informed about how we are protecting your personal data.
            </p>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs={12} md={6}>
            <h3>Contact Us</h3>
            <p>
                If you have any questions or concerns about this privacy policy or our data practices, please contact us at onwardflightticket@gmail.com.
            </p>
            </Col>
        </Row>
    </Container>
    )
}
export default PrivacyPolicy;