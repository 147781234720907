import React, { Component } from 'react';
import BookTicket from './BookTicket';
import * as constClass from '../constants/agent.js';

class Flight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dTime: '',
      aTime: '',
      flyTo: '',
      flyFrom: '',
      price: '',
      flyDuration: '',
      selectWasClicked: false
    };
  }

  check_ignore_airline_logo = (airline_identifier) => {
    const ignore_airline = Object.keys(constClass.AIRLINE_IGNORE_LOGO_LIST).indexOf(airline_identifier) > -1
    return ignore_airline  // true or false
  }

  format_datetime_string_to_date = datetime_string => {
    const dateTimeComponents = datetime_string.split('T');
    if (dateTimeComponents.length !== 2) {
        throw new Error('Invalid ISO string format');
    }

    const dateComponent = dateTimeComponents[0];
    const [year, month, day] = dateComponent.split('-');

    return `${year}/${month}/${day}`;
  }

  format_datetime_string_to_time = isoString => {
    const dateTimeComponents = isoString.split('T');
    if (dateTimeComponents.length !== 2) {
        throw new Error('Invalid ISO string format');
    }

    const timeComponent = dateTimeComponents[1].split('.')[0]; // Removing milliseconds part
    const [hours, minutes] = timeComponent.split(':');

    return `${hours}:${minutes}`;
  }

  secondsToHours = (seconds) => {
    var hours = Math.floor(seconds / 3600); // get the number of hours
    var remainingSeconds = seconds % 3600; // get the remaining seconds
    var minutes = Math.floor(remainingSeconds / 60); // get the number of minutes
  
    // construct the string
    var str = hours.toString().padStart(2, '0') + ':' +
              minutes.toString().padStart(2, '0');
  
    return str;
  }

  handleSelectFlight = () => {
    console.log("SELECT clicked")
    this.setState({ selectWasClicked: !this.state.selectWasClicked })
  }

  render() {
    const selectWasClicked = this.state.selectWasClicked;
    let button;

    if (selectWasClicked){
      button = <button type="button" className="btn btn-lg btn-light" onClick={this.handleSelectFlight}>Close</button>;
    } else {
      button = <button type="button" className="btn btn-lg btn-primary" onClick={this.handleSelectFlight}>Select &rarr;</button>;
    }

    let airline_image_element;
    let airline = this.props.data.airlines[0];
    let imageUrl;
    let airlineName; // Show this text if airline logo can't be shown
    if (!this.check_ignore_airline_logo(airline)) {
      imageUrl = `https://images.kiwi.com/airlines/64/${airline}.png`;
    } else {
      imageUrl = `https://images.kiwi.com/airlines/64/non-existing.png`;
      airline = constClass.AIRLINE_IGNORE_LOGO_LIST[airline] // Airline name human readable
      airlineName = airline  // So I can conditionally show this text
    }
    airline_image_element = <img src={imageUrl} className="img-fluid" alt={airline} />

    return (
    <tr className="table-light">
        <div className="container text-dark mb-4">
          <div className="row justify-content-left">
            <div className="col">
            <div className="row">
              <div className="col">
                  <strong>{airlineName}</strong>
                </div>
            </div>
            <div className="row">
              <div className="col">
              {airline_image_element}
              </div>
            </div>
            <div className="row">
              <div className="col">
              <strong>Date:</strong> {this.format_datetime_string_to_date(this.props.data.local_departure)}
              </div>
            </div>

            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <h3>
                  {this.format_datetime_string_to_time(this.props.data.local_departure)}
                  </h3>
                </div>
              </div>
              <div className="row text-weight-bold">
                <div className="col">
                {this.props.data.cityFrom} ({this.props.data.flyFrom})
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  Duration:
                </div>
              </div>
              <div className="row">
                <div className="col">
                <strong>{this.secondsToHours(this.props.data.duration.total)}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col">
                &#8594;
                </div>
              </div>
              <div className="row">
                <div className="col">
                {this.props.data.route.length-1} stopovers
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <h3>
                  {this.format_datetime_string_to_time(this.props.data.local_arrival)}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col">
                {this.props.data.cityTo} ({this.props.data.flyTo})
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                <strike>{this.props.data.price} $</strike>
                </div>
              </div>
              <div className="row">
                <div className="col">

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
            </div>
            <div className="col d-flex justify-content-end">
            {button}
            </div>
          </div>
          {this.state.selectWasClicked ? <BookTicket data={this.props.data}/> : null}
        </div>
    </tr>
    );
  }
}

export default Flight
