import React from 'react';

// Constants
import * as constClass from '../constants/agent.js';

//Page imports
import BasePage from './BasePage';

import CheckoutSuccess from '../components/payment/Success'
import CheckoutCancel from '../components/payment/Cancel'
import FlightsForm from '../components/FlightsForm';
import Terms from '../components/Terms';
import PrivacyPolicy from '../components/PrivacyPolicy';
import NotFound from '../components/errors/NotFound';
import MaintenanceMode from '../components/MaintenanceMode';

// Blog
import BlogPost from '../components/blog/BlogPost';
import BlogList from '../components/blog/BlogList';
import blogEntries from '../components/blog/blogEntries';

// Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"



export default function RoutingBase () {
    if (!constClass.MAINTENANCE_MODE) {
        return (
            <Router>
                <Routes>
                    <Route
                        exact
                        path='/'
                        index
                        element={<FlightSearchPage />}
                    />
                    <Route
                        exact
                        path='/checkout-success'
                        element={<CheckoutSuccessPage/>}
                    />
                    <Route
                        exact
                        path='/checkout-failed'
                        element={<CheckoutCancelPage/>}
                    />
                    <Route
                        exact
                        path='/terms'
                        element={<TermsPage/>}
                    />
                    <Route
                        exact
                        path='/privacy-policy'
                        element={<PrivacyPolicyPage/>}
                    />
                    
                    <Route
                        exact
                        path='/blog'
                        element={<BlogListPage entries={blogEntries} />}
                    />
                    <Route
                        path="/blog/:slug"
                        element={<BlogPostPage entries={blogEntries}/>}
                        />
                    <Route
                        exact
                        path='/*'
                        element={<NotFoundPage/>}
                    />
                </Routes>
            </Router>
        );             
    } else {
        return(
        <Router>
            <Routes>
                <Route
                    exact
                    path='/'
                    index
                    element={<MaintenanceModePage />}
                />
                <Route
                    exact
                    path='/*'
                    element={<NotFoundPage/>}
                />
            </Routes>
        </Router>
        )
    }
}

//////////////////////////////
// Pages
function FlightSearchPage() {
  return (
      <BasePage>
        <FlightsForm />
      </BasePage>
  );
}

function CheckoutSuccessPage() {
    return (
        <BasePage>
          <CheckoutSuccess />
        </BasePage>
    );
}

function CheckoutCancelPage() {
    return (
        <BasePage>
          <CheckoutCancel />
        </BasePage>
    );
}

function TermsPage() {
    return (
        <BasePage>
          <Terms />
        </BasePage>
    );
}

function PrivacyPolicyPage() {
    return (
        <BasePage>
          <PrivacyPolicy />
        </BasePage>
    );
}

function NotFoundPage() {
    return (
        <BasePage>
          <NotFound />
        </BasePage>
    );
}

function BlogListPage({ entries }) {
    return (
        <BasePage>
          <BlogList entries={entries} />
        </BasePage>
    );
}

function BlogPostPage({ entries }) {
    return (
        <BasePage>
          <BlogPost entries={entries} />
        </BasePage>
    );
}

function MaintenanceModePage() {
    return (
        <BasePage>
            <MaintenanceMode />
        </BasePage>
    )
}