import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';


const ExampleTicket = ({ headline, ticketImage }) => {
  return (
    <Container>
      <Row>
        <Col md={{ span: 12, offset: 0 }}>
          <Card className="bg-light border-0">
            <Card.Body>
              <Row>
                <Col>
                <h5>{headline}</h5>
                  <img src={ticketImage} alt="example-ticket" className="img-fluid" />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ExampleTicket;
