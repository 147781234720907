import React, { useEffect, useState } from 'react';
import { Stripe } from '../../utils/requests'
import { useLocation, useSearchParams } from "react-router-dom";
import SocialShareBar from '../SocialShareBar'

const Success = () => {
  ///////////////////////////////////////////////////////
  // State declarations
  const [emailAddress, setEmailAddress] = useState("");

  ///////////////////////////////////////////////////////
  // Constants
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();  //
  const session_id = searchParams.get("session_id")

  ///////////////////////////////////////////////////////
  // Method declaration
  const setEmailFromStripeCheckoutSessionData = async (session_id) => {
    const response_json = await (await Stripe.retrieveCheckoutSessionData(session_id)).json()
    setEmailAddress(response_json.email);
  }

  useEffect( () => {
    ///////////////////////////////////////////////////////
    // Case 1 data - Free

    // If state exists, then the user was navigated to this page programmatically from the frontend
    if (state ) {
      const { email } = state; // Read values passed on state
      setEmailAddress(email);
    }

    ///////////////////////////////////////////////////////
    // Case 2 data - Paid
  
    // Got redirected from Stripe Checkout with GET parameter "session_id"
    // Fetch the session_id data from api.onwardflightticket.com

    // Testing for !emailAddress avoids multiple requests to the backend due to re-rendering
    if (session_id && !emailAddress) {
      setEmailFromStripeCheckoutSessionData(session_id);
    }
  })


  return (
    <>
        <div className="row justify-content-center mt-2">
          <div className="col-md-6 col-sm-10">
              <SocialShareBar />
            </div>
        </div>

        <div className="row justify-content-center mt-2">
          <div className="col-md-6 col-sm-10">
            <h2>
              <strong>Check your inbox</strong>
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-10">
            <h4>
            ({emailAddress})
            </h4> 
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-10">
            <h6>
              <svg xmlns="http://www.w3.org/2000/svg" fill="green" width="30%" className="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
              </svg>
            </h6>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-10">
            <h3 className="text-success">Success!</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-10">
            <p>
              We've sent a personalized sample ticket to your e-mail address.
            </p>
            <p>
            If you don’t receive an email within a few minutes it may have gone to your junk or spam mail folders by mistake. So check there first!
            </p>
            <a type="button" className="btn btn-lg btn-primary mt-2 mb-2" href="/">Create another ticket</a>
            <br/>
          </div>        
        </div>     
    </>
  )
}

export default Success