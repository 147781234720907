import React from 'react';

import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const SocialShareBar = (props) => {
    const buttonSize = 32;
  const onwardflightticket_url = "https://onwardflightticket.com";

  // Share Buttons contents
  const msg = "Create a personalized fake flight ticket on:";
  const email_subject = "Dummy flight ticket";
  const email_body = "Create a dummy ticket with real flight information on:";

  return (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col">
        <WhatsappShareButton
          title={msg}
          url={onwardflightticket_url}
        >
          <WhatsappIcon size={buttonSize} />
        </WhatsappShareButton>

        <FacebookShareButton
          quote={msg}
          url={onwardflightticket_url}
        >
          <FacebookIcon size={buttonSize} />
        </FacebookShareButton>

        <TelegramShareButton
          url={onwardflightticket_url}
        >
          <TelegramIcon size={buttonSize} />
        </TelegramShareButton>

        <TwitterShareButton
          title={msg}
          url={onwardflightticket_url}
        >
          <TwitterIcon size={buttonSize} />
        </TwitterShareButton>

        <RedditShareButton
          title={msg}
          url={onwardflightticket_url}
        >
          <RedditIcon size={buttonSize} />
        </RedditShareButton>

        <EmailShareButton
          subject={email_subject}
          body={email_body}
          url={onwardflightticket_url}
          hashtags={['keplergl']}
        >
          <EmailIcon size={buttonSize} />
        </EmailShareButton>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col">
        <p><strong>Let your friends know about us :)</strong></p>
      </div>
    </div>
  </div>
  );
}

export default SocialShareBar;
