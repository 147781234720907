export const IS_LOCAL_INSTANCE = false
export const BACKEND_URL =  `${IS_LOCAL_INSTANCE ? "http://localhost:8000" : "https://api.onwardflightticket.com"}`
export const FLIGHT_PDF_BACKEND_URL = `${BACKEND_URL}/ticket/hello.pdf`
export const AUTOCOMPLETE_AIRPORT_BACKEND_URL = `${BACKEND_URL}/api/airports/`
export const LOCAL_BACKEND_URL = "http://localhost:8000" // Deprecated ToDo Remove from code
export const FLIGHT_PDF_LOCAL_BACKEND_URL = "http://localhost:8000/ticket/hello.pdf" // Deprecated ToDo Remove from code

// Airlines to ignore logos
export const AIRLINE_IGNORE_LOGO_LIST = {"FD": "AirAsia"}

// Stripe
export const STRIPE_CHECKOUT_URL = `${IS_LOCAL_INSTANCE ? LOCAL_BACKEND_URL : BACKEND_URL}/payment/create-checkout-session/`

export const REACT_APP_PUBLIC_POSTHOG_KEY="phc_256VXpa4F8oa7FWFzmFQ02of7ySsuU6iHxfI8Q1EcVO"
export const REACT_APP_PUBLIC_POSTHOG_HOST="https://eu.posthog.com"

export const MAINTENANCE_MODE = false

// Paypal
export const PAYPAL_CLIENT_ID = `${IS_LOCAL_INSTANCE ? "test" : "ARP4o2bc9C1qGqpipTvRlnkqYj5mKUbrxdms12EDm-jRga4L1IFrw4rH5UTZqWpdsoqS6DU8M4VSLx1C"}`

// Config
export const ENABLE_PRO_TICKET = true
export const ENABLE_STRIPE_PAYMENT = false
export const ENABLE_COINBASE_PAYMENT = true
export const ENABLE_PAYPAL_PAYMENT = true
export const CURRENCY = "USD"
export const ENABLE_FREE_TICKET = false