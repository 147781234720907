import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as constClass from './constants/agent.js';
import * as serviceWorker from './serviceWorker';

// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Posthog
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'


//////////////////////
// CSS Imports
import './index.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';  // Import css for react-bootstrap-range-slider (https://github.com/jaywilz/react-bootstrap-range-slider)
//////////////////////

//////////////////////
// Sentry
Sentry.init({
  dsn: "https://be2c3777002d4fdc91f0235d10078259@o199994.ingest.sentry.io/4504150043262976",
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.01,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.2,
});
//////////////////////

//////////////////////
// Posthog

posthog.init(
  constClass.REACT_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: constClass.REACT_APP_PUBLIC_POSTHOG_HOST,
    opt_in_site_apps: true, 
    session_recording: {
      maskAllInputs: false,
    },
    disable_session_recording: false,
  }
);
//////////////////////

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
