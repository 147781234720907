import React, { Component } from 'react';
import Flight from './Flight'

class FlightsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    //console.log("[FlightsList.js] flightData")
    //console.log(this.props.flightData)
    if (this.props.flightData !== null && this.props.flightData !== undefined && this.props.flightData.length !== 0) {
      const data = this.props.flightData;
      //console.log("[FlightsList.js] data")
      //console.log(data)
      const flights_html = data.map(
        (name, index) => {
          return <Flight key={index} data={name} />
        });
      return(
        <div className="container mt-4">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-10">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <div className="alert alert-primary alert-dismissible fade show" role="alert">
                        <p>Choose your flight from below. This flight's details will be imprinted on your personal ticket!</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {flights_html}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else if (this.props.flightData !== null && this.props.flightData !== undefined && this.props.flightData.length == 0) {
        return(
          <div className="container mt-2">
            <div className="row justify-content-center">
              <div className="alert alert-warning" role="alert">
              <h6>Sorry, no flights were found.</h6>
              <p><strong>Please try another date or destination!</strong><br/>
              <strong>Make sure you choose a valid destination from the dropdown menu!</strong></p>
              </div>
            </div>
          </div>
        );
    } else {
      if (this.props.loadingFlights) {
        return (
          <div className="container mt-2">
            <div className="row justify-content-center">
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-success" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-danger" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        );
      }
      else if (!this.props.firstSearchDone) {
        return (
          <div className="container mt-2">
            <div className="row justify-content-center">
              <div className="alert alert-secondary" role="alert">
                <p><strong>Fill in the form above and receive your ticket in just 30 seconds.</strong></p>
              </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <div className="container">
            <div className="row justify-content-center">
              <p>No Flights found.</p>
            </div>
          </div>
        );
    }
    }
  }
}

export default FlightsList
