import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const MaintenanceMode = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <div className="text-center">
            <h1 className="mb-4">Maintenance</h1>
            <img src='/images/undraw_maintenance.svg' alt="maintenancet" className="img-fluid" />
            <hr />
            <p>We're sorry for the inconvenience, but we're performing some maintenance at the moment.</p>
            <p>Please check back later.</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceMode;