import React, { Component } from 'react';
import NameEmailForm from './NameEmailForm';



class BookTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="row">
        <div className="col">
            <NameEmailForm data={this.props.data}/>
        </div>
      </div>
    )
  }
}

export default BookTicket
