import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center">Oops! Page Not Found</h1>
          <p className="text-center">The page you are looking for does not exist.</p>
          <div className="d-flex justify-content-center">
            <Button href="/" variant="primary">Go to Home Page</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;