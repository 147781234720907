import React from 'react';

const CurrencySelector = ({ currency, onCurrencyChange }) => {
  const currencyOptions = [
    { value: 'USD', label: 'USD ($)' },
    { value: 'EUR', label: 'EUR (€)' },
    { value: 'GBP', label: 'GBP (£)' },
    { value: 'JPY', label: 'JPY (¥)' },
    { value: 'AUD', label: 'AUD ($)' },
    { value: 'CAD', label: 'CAD ($)' },
    { value: 'CHF', label: 'CHF (Fr.)' },
    { value: 'CNY', label: 'CNY (¥)' },
    { value: 'HKD', label: 'HKD ($)' },
    { value: 'INR', label: 'INR (₹)' },
    { value: 'KRW', label: 'KRW (₩)' },
    { value: 'MXN', label: 'MXN ($)' },
    { value: 'NZD', label: 'NZD ($)' },
    { value: 'RUB', label: 'RUB (₽)' },
    { value: 'SEK', label: 'SEK (kr)' },
  ];

  return (
    <label htmlFor="currency-select" className="form-label">
      Currency on the ticket <span className="text-secondary text-sm">(optional)</span>
      <select
        id="currency-select"
        className="form-control"
        value={currency}
        onChange={(e) => onCurrencyChange(e)}
      >
        <option value="">Select a currency</option>
        {currencyOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default CurrencySelector;
