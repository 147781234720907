import React from 'react';
import * as constClass from './constants/agent.js';
import RoutingBase from './containers/RoutingBase';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";


function App() {
  return (
    <div className="App">
      <PayPalScriptProvider options={{ "client-id": constClass.PAYPAL_CLIENT_ID }}>
        <RoutingBase />
      </PayPalScriptProvider>
    </div>
  );
}

export default App;
