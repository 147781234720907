import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Terms() {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Terms of Service</h3>
          <p>Welcome to onwardflightticket.com! These terms of service ("Terms") apply to your use of our website and services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Use of the Service</h3>
          <p>Our Service generates sample flight tickets for your personal use only for 5 USD. You agree not to use the sample ticket for any illegal purposes, and we are not responsible for any such misuse.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>User Data</h3>
          <p>When you use our Service to generate a sample ticket, we will collect and use your email address to send the sample ticket to you. Your email address will be deleted from our records after the sample ticket has been sent. However, if you choose to subscribe to our newsletter by checking the relevant box when downloading the sample ticket, we will save your email address to send you the newsletter.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Intellectual Property</h3>
          <p>All content and materials available on the Service, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of onwardflightticket.com and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Disclaimer of Warranties</h3>
          <p>The Service is provided "as is" without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose. We do not warrant that the Service will be uninterrupted or error-free, nor do we make any warranty as to the accuracy, reliability or currency of any information or content provided through the Service.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Limitation of Liability</h3>
          <p>In no event shall onwardflightticket.com or its affiliates be liable for any direct, indirect, incidental, special or consequential damages arising out of or in any way connected with the use of the Service or with the delay or inability to use the Service, or for any information or content obtained through the Service, or otherwise arising out of the use of the Service, whether based on contract, tort, strict liability or otherwise, even if onwardflightticket.com has been advised of the possibility of such damages.</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Modification of Terms</h3>
          <p>We reserve the right to modify these Terms at any time without prior notice. Your continued use of the Service after any modifications to these Terms constitutes your acceptance of the modified Terms.</p>
        </Col>
      </Row>

      <Row>
        <Col>
        <p>If you have any questions about these Terms of Service, please contact us.</p>
        </Col>
      </Row>
    </Container>
  )
}
export default Terms; 