import React from 'react';

const Cancel = () => {
    return (
        <>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-6">
            <h3>Payment was not successful</h3>
            Your account was <strong>not charged</strong>.
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-6">
            <a type="button" className="btn btn-primary mt-2 mb-2" href="/">Try again</a>
            <br/>
          </div>        
        </div>     
    </>
    )
}

export default Cancel