import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BlogList = ({ entries }) => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Blog</h1>
        </Col>
      </Row>
      {entries.length === 0 ? (
        <p>No entries to display.</p>
      ) : (
        <Row>
          {entries.reverse().map((entry) => (
            <Col md={4} key={entry.id} style={{ marginBottom: '20px' }}>
              <Card style={{ height: '100%' }}>
                <Card.Img variant="top" src={entry.image} />
                <Card.Body>
                  <Card.Title>{entry.title}</Card.Title>
                  <Card.Text>{entry.excerpt}</Card.Text>
                </Card.Body>
                <Card.Footer>
                <Row>
                    <Col>
                      <Button as={Link} to={`/blog/${entry.slug}`}>
                        Read More
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <small className="text-muted">{entry.date}</small>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default BlogList;