import React, { Component } from 'react';
import * as constClass from '../constants/agent.js';
import PayWhatYouWantCheckout from './payment/PayWhatYouWantCheckout'

import Button from 'react-bootstrap/Button';

import DatePicker from "react-datepicker";

// For now this form expects all data need by the flightticket-pdf-backend
// to be passed as props.

class NameEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      subscribe_to_newsletter: true, // Equals false. Set to "on" when user clicks box
      show_modal: false,
      customer_data: {},
      flight_data: {},
      extra_data: {},
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        valid: {
          first_name: false,
          last_name: false,
          email: false,
        }
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = name => event => {
    if (name === "first_name") {
      this.validateFirstName(event.target.value);
    }

    else if (name === "last_name") {
      this.validateLastName(event.target.value);
    }

    else if (name === "email" ) {
      this.validateEmail(event.target.value);
    }

    this.setState({[name]: event.target.value});
  }

  handleCheckboxChange = name => event => {
    this.setState({[name]: event.target.checked})
  }


// Form validation functions

  validateFirstName = name => {
    //ToDo Maybe just validate length here
    if (name.length < 2) {
      let errors = {...this.state.errors}
      errors.first_name = 'Please enter your first name.'
      errors.valid.first_name = false
      this.setState({errors})
    }
    else {
      let errors = {...this.state.errors}
      errors.first_name = ''
      errors.valid.first_name = true
      this.setState({errors})
    }
  }

  validateLastName = name => {
    //ToDo Maybe just validate length here
    if (name.length < 2) {
      let errors = {...this.state.errors}
      errors.last_name = 'Please enter your last name.'
      errors.valid.last_name = false
      this.setState({errors})
    }
    else {
      let errors = {...this.state.errors}
      errors.last_name = ''
      errors.valid.last_name = true
      this.setState({errors})
    }
  }


  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if  (re.test(String(email).toLowerCase()) === false) {
      let errors = {...this.state.errors}
      errors.email = 'Please enter a valid e-mail address.'
      errors.valid.email = false
      this.setState({errors})
    }
    else {
      let errors = {...this.state.errors}
      errors.email = ''
      errors.valid.email = true
      this.setState({errors})
    }
  }

  enableCheckoutButton = () => {
    if (
      this.state.errors.valid.first_name === true &&
      this.state.errors.valid.last_name === true &&
      this.state.errors.valid.email === true
    ) {
      return true
    } else {
      return false
    }
  }


  async handleSubmit(event) {
    event.preventDefault();
    const first_name = event.target.elements[0].value; // TODO Should use this.state
    const last_name = event.target.elements[1].value; // TODO Should use this.state
    const email = event.target.elements[2].value; // TODO Should use this.state
    const subscribe_to_newsletter = event.target.elements[4].checked // TODO Should use this.state
    const flight_name = this.props.data.airlines[0]
    const departure_time = this.props.data.local_departure
    const arrival_time = this.props.data.local_arrival
    const fly_to = this.props.data.flyTo
    const fly_from = this.props.data.flyFrom
    const city_to = this.props.data.cityTo
    const city_from = this.props.data.cityFrom
    const price = this.props.data.price
    const fly_duration = this.props.data.duration.total
    const route = this.props.data.route
    const airline = this.props.data.airlines[0]

    // Write customer_data to state
    this.setState({
      customer_data: {
        first_name: first_name,
        last_name: last_name,
        email: email
      }
    })
    // Write flight data to state
    this.setState({
      flight_data: {
        airline: airline,
        arrival_time: arrival_time,
        city_from: city_from,
        city_to: city_to,
        departure_time: departure_time,
        flight_name: flight_name,
        fly_duration: fly_duration,
        fly_from: fly_from,
        fly_to: fly_to,
        price: price,
        route: route,
      }
    })
    // Write extra data to state
    this.setState({
      extra_data: {
        subscribe_to_newsletter: subscribe_to_newsletter
      }
    })

    // Open PayWhatYouWant component
    console.log("[*] Opening checkout modal")
    this.handleShowModal();
  }

  ////////////////////////////////////////////////////////////////////////
  // Modal functions
  handleCloseModal = () => this.setState({show_modal: false})
  handleShowModal = () => this.setState({show_modal: true})
  ////////////////////////////////////////////////////////////////////////

  render() {
    const checkout_button =
      <button className="btn btn-lg btn-primary" type="submit" disabled={this.enableCheckoutButton() === true ? false : true }>Checkout</button>

      // Only start validating after the user put in at least two charachters.
      const firstName_input = this.state.first_name.length <= 2 ?
        <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" value={this.state.first_name} onChange={this.handleChange("first_name")} className="form-control" placeholder="John" />
        </div>
        :
        <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" value={this.state.first_name} onChange={this.handleChange("first_name")} className={this.state.errors.valid.first_name ? "form-control is-valid" : "form-control is-invalid"} placeholder="John" />
            <div className="invalid-feedback">
              {this.state.errors.first_name}
            </div>
        </div>

        // Only start validating after the user put in at least two charachters.
      const lastName_input = this.state.last_name.length <= 2 ?
        <div className="form-group">
          <label htmlFor="familyName">Last Name</label>
          <input type="text" id="familyName" value={this.state.last_name} onChange={this.handleChange("last_name")} className="form-control" placeholder="Doe" />
        </div>
        :
        <div className="form-group">
          <label htmlFor="familyName">Last Name</label>
          <input type="text" id="familyName" value={this.state.last_name} onChange={this.handleChange("last_name")} className={this.state.errors.valid.last_name ? "form-control is-valid" : "form-control is-invalid"} placeholder="Doe" />
          <div className="invalid-feedback">
            {this.state.errors.last_name}
          </div>
        </div>

      // Only start validating after the user put in at least two charachters.
      const email_input = this.state.email.length <= 0 ?
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" value={this.state.email} onChange={this.handleChange("email")} className="form-control" placeholder="Enter email" aria-describedby="emailHelpBlock"/>
          <small id="emailHelpBlock" className="form-text text-danger">
          Your ticket will be sent to this email.
          </small>
        </div>
        :
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" value={this.state.email} onChange={this.handleChange("email")} className={this.state.errors.valid.email ? "form-control is-valid" : "form-control is-invalid"} placeholder="Enter email"/>
          <small id="emailHelpBlock" className={this.state.errors.valid.email ? "form-text text-success" : "form-text text-danger"}>
          Your ticket will be sent to this email.
          </small>
          <div className="invalid-feedback">
            {this.state.errors.email}
          </div>
        </div>

        const agree_to_terms =
        <div className="form-group">
          <input type="checkbox" className="form-check-input" id="agree_to_terms" required/>
          <label className="form-check-label" htmlFor="agree_to_terms">Agree to <a href="/terms" target="_blank">Terms of Service</a></label>
        </div>

        const subscribe_to_newsletter =
          <div className="form-group">
            <input type="checkbox" className="form-check-input" id="subscribe_to_newsletter" checked={this.state.subscribe_to_newsletter} onChange={this.handleCheckboxChange("subscribe_to_newsletter")}/>
            <label className="form-check-label" htmlFor="subscribe_to_newsletter">Subscribe to newsletter</label>
          </div>

    // Debugging
    const debug_modal_button =
    <Button variant="primary" onClick={this.handleShowModal}>
      Launch demo modal
    </Button>
    
    return (
      <div className="container-fluid">
        <div className="row justify-content-left">
          <div className="col-sm-12 col-md-8">
            <form onSubmit={this.handleSubmit}>
              {firstName_input}
              {lastName_input}
              {email_input}
              {agree_to_terms}
              {subscribe_to_newsletter}
              {checkout_button}
              {constClass.IS_LOCAL_INSTANCE ? debug_modal_button : null}
            </form>
          </div>
          <br/>
        </div>
        <PayWhatYouWantCheckout
          customer_data={this.state.customer_data}
          extra_data={this.state.extra_data}
          flight_data={this.state.flight_data}
          is_visible={this.state.show_modal}
          handleCloseModal={this.handleCloseModal}
        />
      </div>

    );
  }
}

export default NameEmailForm;
