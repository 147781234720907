import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { FlightTicket } from '../../utils/requests';

// This values are the props in the UI
const style = {
    "layout":"vertical",
    "shape": "pill",
    "color": "white",
};

// Custom component to wrap the PayPalButtons and handle currency changes
const PaypalButton = ({
        currency,
        showSpinner,
        customer_data,
        flight_data,
        extra_data,
        pro_ticket_options
     }) => {
    // Routing
    const navigate = useNavigate();

    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    // Receive customer, extra and flight data from props
      // Customer data
      const {
        first_name,
        last_name,
        email} = customer_data;
      // Flight data
      const {
        airline,
        arrival_time,
        city_from,
        city_to,
        departure_time,
        flight_name,
        fly_duration,
        fly_from,
        fly_to,
        price,
        route} = flight_data;
      // Extra data
      const {
        subscribe_to_newsletter
      } = extra_data;

      const {
        extraPassengers,
        currencyOnTicket,
        optionalPnrNumber,
        flightClass
      } = pro_ticket_options;

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);


    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[
                    currency,
                    style,
                    customer_data,
                    flight_data,
                    extra_data,
                    pro_ticket_options
                ]}
                //fundingSource="paypal"
                createOrder={async (data, actions) => {
                    const response = await FlightTicket.orderTicketPaypal(
                        first_name,
                        last_name,
                        email,
                        airline,
                        arrival_time,
                        city_from,
                        city_to,
                        departure_time,
                        flight_name,
                        fly_duration,
                        fly_from,
                        fly_to,
                        price,
                        route,
                        subscribe_to_newsletter,
                        "5", // 5 times 1$ ticket price
                        // Pro ticket extra options
                        extraPassengers,
                        currencyOnTicket,
                        optionalPnrNumber,
                        flightClass
                    )

                    const paypalOrderId = await response.json();
                    console.log("Paypal Order ID: ", paypalOrderId)

                    return paypalOrderId
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                        // Code after capture the order
                        // Navigate to success page
                        navigate("/checkout-success", {state : { email: email }})
                    });
                }}
                onError={function (err) {

                    // For example, redirect to a specific error page
                    console.log("Error: ", err)
                    navigate("/checkout-failed")
                }}
            />
        </>
    );
}

export default PaypalButton;