const blog_entries_path = '/blog_entries'

const blogEntries = [
  {
    id: 1,
    title: 'What is proof of onward travel?',
    slug: 'what-is-proof-of-onward-travel',
    content_path: '/blog_entries/what-is-proof-of-onward-travel.md',
    image: 'https://picsum.photos/536/536',
    date: '2023-01-01'
  },
  {
    id: 2,
    title: 'Which countries require proof of onward travel?',
    slug: 'which-countries-require-proof-of-onward-travel',
    content_path: '/blog_entries/which-countries-require-proof-of-onward-travel.md',
    image: 'https://picsum.photos/536/536',
    date: '2023-01-15'
  },
  {
    id: 3,
    title: 'Thailand Secret Islands',
    slug: 'thailand-secret-islands',
    content_path: '/blog_entries/thailand-secret-islands.md',
    image: 'https://picsum.photos/536/536',
    date: '2023-02-02'
  }
];


export default blogEntries;